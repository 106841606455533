import { Field, useFormikContext } from 'formik'
import { CountryCode } from 'libphonenumber-js/min'
import React, { ReactElement, ChangeEvent, useMemo, useState, useCallback } from 'react'

import PhoneField from '@components/PhoneField'
import useValidators from '@hooks/useValidators'
import ancillaryUtils from '@lib/ancillary'
import { useTranslation } from '@lib/i18n'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { useSettings } from '@queries/settings'
import { Checkbox } from '@ui'
import InputField from '@ui/Input/Field'

const SUPPORTED_COUNTRY: CountryCode[] = ['US', 'CA']
const US_COUNTRY: CountryCode = 'US'

const ContactDetails = (): ReactElement => {
  const { t } = useTranslation()
  const [disabledAlert, setDisabledAlert] = useState<boolean>(false)
  const [{ phone, quickReservation, email }] = useSettings()
  const { required, phone: validatePhone, validateUSPhone, combine, email: validateEmail, same } = useValidators()
  const {
    values: { vacancy, ancillaries, contact },
    setFieldValue,
  } = useFormikContext<CheckoutFormData>()

  const phoneRequired = quickReservation.enabled
  const emailRequired = !email.optional
  const phoneFieldValidators = useMemo(
    () => (phoneRequired ? [required, validatePhone] : [required]),
    [phoneRequired, required, validatePhone],
  )
  const ancillary = ancillaryUtils.getByCategory('ALERT', vacancy?.ancillaries)

  const handleChange = ({ target: { checked } }: ChangeEvent<HTMLInputElement>): void => {
    const value = checked ? ancillary : []

    setFieldValue('ancillaries.ALERT', value)
  }

  const handleDisableAlert = useCallback(
    (code: CountryCode, value?: string): void => {
      const isValid = code === US_COUNTRY ? !validateUSPhone(value) : SUPPORTED_COUNTRY.includes(code)

      if (!isValid) setFieldValue('ancillaries.ALERT', [])

      setDisabledAlert(!isValid)
    },
    [setFieldValue, validateUSPhone],
  )

  const validateMatchEmail = useMemo(() => same(contact.email, t('errors.unmatchedEmail')), [same, contact.email, t])

  const emailFieldValidators = useCallback(
    (validateMatch?: boolean) => {
      const validators = []

      if (emailRequired) validators.push(required, validateEmail)
      if (validateMatch) validators.push(validateMatchEmail)
      if (contact.email) validators.push(validateEmail)

      return validators
    },
    [emailRequired, required, validateEmail, contact.email, validateMatchEmail],
  )

  return (
    <div>
      <div>
        <h3 className="mb-1">{t('checkout.contactDetails.title')}</h3>
        <div className="mb-5">{t('checkout.contactDetails.description')}</div>
        <div className="row gap-3 column-sm wrap">
          {email.enabled && (
            <div className="cell-6 cell-sm-12">
              <Field
                component={InputField}
                label={t('checkout.contactDetails.email')}
                name="contact.email"
                validate={combine(emailFieldValidators())}
                required={emailRequired}
                updateEvent="onBlur"
              />
            </div>
          )}
          {email.verification && email.enabled && (
            <div className="cell-6 cell-sm-12">
              <Field
                component={InputField}
                label={t('checkout.contactDetails.confirmEmail')}
                name="contact.confirmEmail"
                validate={combine(emailFieldValidators(!!contact.email))}
                required={emailRequired}
              />
            </div>
          )}
          {phone.enabled && (
            <div className="cell-6 cell-sm-12">
              <Field
                validate={combine(phoneFieldValidators)}
                validateCountry={handleDisableAlert}
                component={PhoneField}
                label={t('checkout.contactDetails.phone')}
                name="contact.phone"
                required
              />
            </div>
          )}
        </div>
      </div>
      {!!ancillary.length && (
        <div className="mt-5">
          <h4 className="mb-2">{t('extras.ancillary.alert.title')}</h4>
          <div className="cell-12">
            <Field
              component={Checkbox}
              onChange={handleChange}
              checked={!!ancillaries.ALERT.length}
              disabled={disabledAlert}
            >
              <span>{t('extras.ancillary.alert.description')}</span>
            </Field>
          </div>
        </div>
      )}
    </div>
  )
}

export default ContactDetails
