import cn from 'classnames'
import React, { FocusEvent, ReactElement, ReactNode, useState, KeyboardEvent, ChangeEvent } from 'react'

import bem from '@lib/bem'
import Icon from '@ui/Icon'
import InputLabel from '@ui/Input/Label'

import '@ui/Input/index.scss'

type InputType = 'text' | 'date' | 'number'

const DATE_LIMIT_VALUE = 10

export type InputElement = HTMLInputElement | HTMLTextAreaElement

export interface InputProps {
  value: string
  onChange: (value: string) => void
  onFocus?: (e: FocusEvent<InputElement>) => void
  onBlur?: (e: FocusEvent<InputElement>, value: string) => void
  onKeyDown?: (e: KeyboardEvent<InputElement>) => void
  onPressEnter?: (e: KeyboardEvent<InputElement>) => void
  label?: string | null
  icon?: ReactNode
  iconSecondary?: ReactNode
  disabled?: boolean
  errorMessage?: string | null
  name?: string
  required?: boolean
  type?: InputType
  max?: string
  trim?: boolean
  placeholder?: string
  multiline?: boolean
  maxLength?: number
  resettable?: boolean
}

const Input = ({
  value,
  icon,
  iconSecondary,
  label,
  onChange,
  disabled,
  errorMessage,
  onFocus,
  onBlur,
  onKeyDown,
  name,
  required,
  type = 'text',
  max,
  maxLength,
  trim = true,
  placeholder,
  multiline,
  onPressEnter,
  resettable,
}: InputProps): ReactElement => {
  const [isFocused, setIsFocused] = useState<boolean>(false)
  const [isActive, setIsActive] = useState<boolean>(false)
  const labelClassNames = cn('ui-input-label', { 'ui-input-label-with-icon': icon, focused: isFocused || value })
  const inputClassNames = cn('ui-input', {
    'ui-input-error': errorMessage,
    'ui-input-with-icon': icon ?? iconSecondary,
    'ui-input-with-icon--primary': icon,
    'ui-input-with-icon--secondary': iconSecondary,
    'ui-input-with-icon--centered': !label,
  })
  const handleFocus = (e: FocusEvent<InputElement>): void => {
    setIsFocused(true)
    onFocus?.(e)
  }
  const handleBlur = (e: FocusEvent<InputElement>): void => {
    setIsFocused(false)
    setIsActive(false)
    const value = trim ? e.target.value.trim() : e.target.value
    onChange(value)
    onBlur?.(e, value)
  }
  const handleKeyDown = (e: KeyboardEvent<InputElement>): void => {
    /* istanbul ignore next */
    if (type === 'date' && e.code === 'Space') e.preventDefault()

    setIsActive(true)
    onKeyDown?.(e)

    if (e.key === 'Enter') {
      onPressEnter?.(e)
    }
  }

  const handleChange = (e: ChangeEvent<InputElement>): void => {
    /* istanbul ignore next: Chrome behavior can't be reproduced in cypress for date input */
    if (type === 'date' && e.target.value.length > DATE_LIMIT_VALUE) return
    /* istanbul ignore next: cover in https://distribusion.atlassian.net/browse/OWL-2608 */
    if (multiline) {
      e.target.style.minHeight = e.target.scrollHeight + 'px'
    }

    onChange(e.target.value)
  }

  const handleReset = (): void => {
    onChange('')
  }

  const inputProps = {
    disabled: disabled,
    value: value,
    className: inputClassNames,
    type: type,
    onChange: handleChange,
    onFocus: handleFocus,
    onBlur: handleBlur,
    onKeyDown: handleKeyDown,
    name: name,
    max: max,
    maxLength,
    placeholder: placeholder,
  }

  return (
    <div>
      <div className={bem('ui-input-wrapper', { active: isActive, focused: isFocused, multiline })}>
        <div className={bem('ui-input-wrapper', 'icon')}>{icon}</div>
        <div className={bem('ui-input-wrapper', 'icon', { secondary: true })}>{iconSecondary}</div>
        {label && <InputLabel className={labelClassNames} text={label} required={required} />}
        {multiline ? <textarea {...inputProps} rows={3} /> : <input {...inputProps} />}
        {resettable && value && <Icon name="cross" size="small" onClick={handleReset} className="ui-input-reset" />}
      </div>
      {errorMessage && (
        <div className="row start ui-input-error-message">
          <Icon name="alert" size="small" />
          <span>{errorMessage}</span>
        </div>
      )}
    </div>
  )
}

export default Input
