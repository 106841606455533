import { addDays, isBefore, parseISO, subDays } from 'date-fns'
import { useState } from 'react'

import { PriceCalendarRequest } from '@api/priceCalendar'
import { SearchFormState } from '@components/SearchForm'
import config from '@config'
import dateUtils from '@lib/date'
import paramsUtils from '@lib/params'
import utils from '@lib/utils'
import { usePriceCalendarLoader } from '@loaders/priceCalendar'
import { useSettings } from '@queries/settings'
import { useParams } from '@stores/params'

type RangeDates = Pick<PriceCalendarRequest, 'departureDateStart' | 'departureDateEnd'>

const DATE_RANGE = 7

interface UsePriceCalendarHook {
  calendarPrices: PriceCalendar | null
  isPriceLoading: boolean
  getCalendarOpened: (opened: boolean) => void
}

const usePriceCalendar = (props: SearchFormState): UsePriceCalendarHook => {
  const [{ currency, retailerPartnerNumber, marketingCarrierCode }] = useParams()
  const [{ priceCalendar }] = useSettings()
  const [opened, setOpened] = useState<boolean>(false)
  const { departureLocation, arrivalLocation, departureDate, departureTime, isReturnTrip } = props

  const rangeDates = (departureDate: Date, range: number): RangeDates => {
    const today = parseISO(dateUtils.formatDate(new Date()))
    const subDay = subDays(departureDate, range)

    if (isBefore(subDay, today)) return rangeDates(departureDate, range - 1)

    return {
      departureDateStart: dateUtils.formatDate(subDay),
      departureDateEnd: dateUtils.formatDate(addDays(subDay, config.priceCalendarDateRange)),
    }
  }

  const { data, isLoading } = usePriceCalendarLoader(
    {
      params: utils.object.compact({
        ...(departureLocation && paramsUtils.flatLocation(departureLocation, 'departure')),
        ...(arrivalLocation && paramsUtils.flatLocation(arrivalLocation, 'arrival')),
        ...rangeDates(departureDate, DATE_RANGE),
        retailerPartnerNumber,
        currency,
        departureStartTime: departureTime,
        marketingCarrierCodes: marketingCarrierCode ? [marketingCarrierCode] : null,
      }),
    },
    {
      enabled: !isReturnTrip && priceCalendar.enabled && opened,
    },
  )

  return {
    calendarPrices: isReturnTrip ? null : data,
    isPriceLoading: isLoading,
    getCalendarOpened: setOpened,
  }
}

export default usePriceCalendar
