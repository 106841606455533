import React, {
  FocusEvent,
  Fragment,
  ReactElement,
  ReactNode,
  useEffect,
  useId,
  useMemo,
  useRef,
  useState,
  MouseEvent,
  KeyboardEvent,
} from 'react'

import { AutocompleteConfig } from '@components/LocationAutocomplete'
import useArrowControls from '@hooks/useArrowControls'
import useClickOutside from '@hooks/useClickOutside'
import useIsMobile from '@hooks/useIsMobile'
import bem from '@lib/bem'
import utils from '@lib/utils'
import { Input, Modal, Option } from '@ui'
import Options from '@ui/Autocomplete/Options'
import { InputProps } from '@ui/Input'

import '@ui/Autocomplete/index.scss'

interface AutocompleteProps<T> {
  value: T | null
  onSelect: (value: T, position: number) => void
  inputValue: string
  onInputChange: (value: string) => void
  options: T[]
  renderOption: (option: T) => ReactNode
  getOptionChildren: (option: T) => T[]
  onInputFocus?: (e: FocusEvent) => void
  onInputBlur?: (e: FocusEvent) => void
  placeholder?: string | null
  inputErrorMessage?: string | null
  inputDisabled?: boolean
  emptyOptionMessage?: string | null
  config?: {
    default?: AutocompleteConfig
    modal?: AutocompleteConfig
  }
  renderListTitle?: (option: T) => ReactNode
}

const Autocomplete = <Item,>({
  value,
  inputValue,
  options,
  renderOption,
  getOptionChildren,
  onInputChange,
  onSelect,
  onInputFocus,
  onInputBlur,
  inputErrorMessage,
  emptyOptionMessage,
  inputDisabled,
  config,
  renderListTitle,
}: AutocompleteProps<Item>): ReactElement => {
  const [opened, setOpened] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  const id = useId()
  const isMobile = useIsMobile()

  useClickOutside(ref, () => {
    !isMobile && setOpened(false)
  })

  const allOptions = useMemo(() => utils.array.flatten(options, getOptionChildren), [getOptionChildren, options])

  const handleSelect = (option: Item, event?: MouseEvent): void => {
    event?.stopPropagation()
    const position = allOptions.indexOf(option) + 1
    onSelect(option, position)
    setOpened(false)
  }

  const { onKeyDown, activeItem, resetActiveItem } = useArrowControls(allOptions, handleSelect, value)

  const handleKeyDown = (e: KeyboardEvent): void => {
    /* istanbul ignore next: cypress doesn't support .type({tab}) command */
    if (e.key === 'Tab') setOpened(false)
    onKeyDown(e)
  }

  const renderOptions = (list: Item[], level: number = 0): ReactNode => {
    return list.map((option, index) => (
      <Fragment key={String(id) + String(index)}>
        {renderListTitle?.(option)}
        <Option
          value={option}
          onSelect={handleSelect}
          selected={option === value}
          active={option === activeItem}
          offset={level ? level * 45 : 16}
          data-tag="autocomplete-option"
        >
          {renderOption(option)}
        </Option>
        {renderOptions(getOptionChildren(option), level + 1)}
      </Fragment>
    ))
  }

  const listClassNames = bem('ui-autocomplete', 'list', { opened })

  useEffect(() => {
    if (!opened) {
      resetActiveItem()
    }
  }, [opened, resetActiveItem])

  const getInput = (props?: Partial<InputProps>) => (
    <Input
      value={inputValue}
      onChange={onInputChange}
      errorMessage={inputErrorMessage}
      onFocus={onInputFocus}
      onBlur={onInputBlur}
      disabled={inputDisabled}
      onKeyDown={handleKeyDown}
      trim={false}
      resettable
      {...props}
    />
  )

  return (
    <>
      <div className="ui-autocomplete" ref={ref} onFocus={() => setOpened(true)} onClick={() => setOpened(true)}>
        {getInput({ ...config?.default })}
        {opened && !isMobile && (
          <Options options={renderOptions(options)} listClassNames={listClassNames} errorMessage={emptyOptionMessage} />
        )}
      </div>
      <Modal
        opened={opened && isMobile}
        className="ui-autocomplete__modal"
        fullScreen
        header={getInput({ ...config?.modal, label: null })}
        title={config?.modal?.label}
        onClose={e => {
          e?.stopPropagation()
          setOpened(false)
        }}
      >
        {<Options options={renderOptions(options)} listClassNames={listClassNames} errorMessage={emptyOptionMessage} />}
      </Modal>
    </>
  )
}

export default Autocomplete
